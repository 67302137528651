import { useNavOpen } from '../store'
import WithApp from '../../_utils/withApp.tsx'
import { Context } from '../../_types'
import { isClient } from '../../_utils'
import Menu from './icons/menu.tsx'

const sidebarCompensation = isClient ? window.innerWidth - document.documentElement.clientWidth : 0

function Component() {
  const { setOpen, open } = useNavOpen()

  const padding = 42 + -(open ? sidebarCompensation : 0) / 2

  return (
    <div
      className="fixed bottom-12 right-[50%] transform no-print"
      style={{
        transform: `translateX(${padding}px)`,
      }}>
      <span
        className="block cursor-pointer rounded-full bg-slate-300 px-5 py-2 hover:bg-slate-400 md:hidden border bolder-solid border-black"
        onClick={() => {
          setOpen(true)
        }}>
        <Menu fullClass="w-10 h-10" />
      </span>
    </div>
  )
}

export default function MobileMenu({ context }: { context: Context }) {
  return (
    <WithApp context={context}>
      <Component />
    </WithApp>
  )
}
